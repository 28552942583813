import { captcha } from './modules/captcha';

/**
 * Modul registrationMobile inituje a nastavuje komponentu registrationMobile,
 * ktera obsluhuje registrační formulář na mobilu.
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se to ma aplikovat
 */
const dynamicRegistration = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const registrationForm = document.querySelectorAll('.main-form.registering:not(.invite)');

    if (registrationForm.length) {
        const { registration } = await import('./modules/registration');

        registration(registrationForm);
    }
};

/**
 * Modul invite inituje a nastavuje komponentu invite,
 * ktera obsluhuje nominační formulář.
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se to ma aplikovat
 */
const dynamicInvite = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const inviteForm = document.querySelectorAll('.main-form.invite');

    if (inviteForm.length) {
        const { invite } = await import('./modules/invite');

        captcha();
        invite(inviteForm);
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicRegistration();
    dynamicInvite();
};

loadDynamicImports();
